<template>
  <div class="cert-list">
    <div class="page-header">
        <el-row>
            <el-col :span="12">
                <div class="header-desc">
                    <p>Branch Name: XXXX</p>
                    <p>Remaining Issues: XX</p>
                </div>
            </el-col>
            <el-col :span="12" align="right">
                <router-link to='/ecert/details'><el-button type="primary" round>Create New ECert</el-button></router-link>
            </el-col>
        </el-row>
    </div>

    <el-row :gutter="20">
        <el-col :span="24">
            <el-card shadow="always">
                <el-collapse v-model="activeName" accordion>
                    <el-collapse-item title="General:" name="1">
                        <el-row :gutter="20">
                            <el-col :span="4" v-for="(item,index) in 9" :key="index">
                                <item-cert></item-cert>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="2019 - IT524110" name="2">
                        <el-row :gutter="20">
                            <el-col :span="4" v-for="(item,index) in 3" :key="index">
                                <item-cert></item-cert>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="2020 ALL Art" name="3">
                        <el-row :gutter="20">
                            <el-col :span="4" v-for="(item,index) in 2" :key="index">
                                <item-cert></item-cert>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="2020 Engineering" name="4">
                        <el-row :gutter="20">
                            <el-col :span="4" v-for="(item,index) in 1" :key="index">
                                <item-cert></item-cert>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                </el-collapse>
            </el-card>
        </el-col>
    </el-row>
  </div>
</template>

<script>
import itemCert from "../../components/itemCert/ItemCert"
export default {
    name: 'cert_list',
    components: {
        itemCert
    },
    data() {
        return {
            activeName: '1'
        };
    }
}
</script>

<style scoped>


</style>
