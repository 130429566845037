<template>
  <div class="item-ecert">
        <div class="item-ecert-img">
            <img src="/image/test-image.jpg" alt="">
        </div>
        <div class="item-ecert-text">
            <p>
                Topic:XXXXXX<br>
                ECert No:XXXXXXXXX<br>
                Create Time:xx/xx/xxxx xx:xx<br>
                Pending Application: XX<br>
                Edit  Share
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'itemCert',
    data() {
        return {

        };
    }
}
</script>

<style scoped>
.item-ecert{
    margin-bottom: 30px;
}
.item-ecert-img img{
    width: 100%;
    height: 350px;
    object-fit: cover;
}
.item-ecert-text{
    padding: 0px 15px;
}
.item-ecert-text p{
    font-size: 15px;
}
</style>